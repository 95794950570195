import FF from "./FormField.module.css";

import { DateField, DateInput, DateSegment, Label, type DateFieldProps, type DateValue } from "react-aria-components";

interface WDateFieldProps<T extends DateValue> extends DateFieldProps<T> {
    label?: string;
}

// TODO: Better integration with `FormField` so `label` / `aria-label` does not have to be duplicated
export function WDateField<T extends DateValue>({ label, ...props }: WDateFieldProps<T>) {
    return (
        <DateField
            className={FF.DateField}
            // TODO: Pick a better hourCycle based on the locale
            hourCycle={24}
            {...props}
        >
            {!!label && <Label>{label}</Label>}
            <DateInput className={FF.DateInput}>
                {(segment) => <DateSegment className={FF.DateSegment} segment={segment} />}
            </DateInput>
        </DateField>
    );
}
