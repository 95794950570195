import FF from "./FormField.module.css";

import type { NumberFieldProps } from "react-aria-components";
import { NumberField } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import { FormFieldChildren, type FormFieldChildrenProps } from "./FormField.tsx";
import { standardFieldAttributes, type StandardFieldProps } from "./standardFieldAttributes.tsx";

export interface WNumberFieldProps
    extends Omit<NumberFieldProps, "children" | "className">,
        StandardFieldProps,
        Pick<FormFieldChildrenProps, "wide" | "description" | "children" | "label" | "errorMessage"> {
    className?: string;
    block?: boolean;
    headless?: boolean;
}

export function WNumberField({
    className,
    children,
    wide,
    block,
    headless,
    description,
    label,
    errorMessage,
    ...props
}: WNumberFieldProps) {
    return (
        <NumberField
            className={cn(FF.FormField, className, block && FF.Block, headless && FF.Headless)}
            {...props}
            formatOptions={{ useGrouping: false }}
            {...standardFieldAttributes(props)}
        >
            <FormFieldChildren wide={wide} description={description} label={label} errorMessage={errorMessage}>
                {children}
            </FormFieldChildren>
        </NumberField>
    );
}
