import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type {
    CustomBillableProduct,
    CustomBillableProductCreateBody,
    CustomBillableProductModifyBody,
} from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { urlEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

type Response = CustomBillableProduct[];

const queryKey: QueryKey = ["custom_products"];

function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> =>
            getResponseData(await client.GET("/charging/admin/products", { signal })),
    });
}

export const queryAtom = atomFromStandardQueryOptions(getQuery);

//#region Mutations
export const customCreateMutation = adminMutation((apiClient) => ({
    entity: "custom billable product",
    operation: "create",
    requestFn: async ({ body }: { body: CustomBillableProductCreateBody }) =>
        await apiClient.POST("/charging/admin/products", {
            ...urlEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const customUpdateMutation = adminMutation((apiClient) => ({
    entity: "custom billable product",
    operation: "update",
    requestFn: async ({ body, uuid }: { body: CustomBillableProductModifyBody; uuid: string }) =>
        await apiClient.PATCH("/charging/admin/products/{uuid}", {
            params: { path: { uuid: uuid } },
            ...urlEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));
//#endregion Mutations
