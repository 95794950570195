import LR from "../../components/LeftRight.module.css";

import { type CalendarDateTime, fromAbsolute, toCalendarDateTime, toTimeZone } from "@internationalized/date";
import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import type { CustomBillableProduct } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import type { FocusEvent, ReactNode } from "react";
import { useMemo, useState } from "react";
import { FormField } from "../../components/forms/FormField.tsx";
import { WDateField } from "../../components/forms/WDateField.tsx";
import { WNumberField } from "../../components/forms/WNumberField.tsx";
import { WSelect } from "../../components/forms/WSelect.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useApiClient } from "../../modules/api/apiClient.store.ts";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import { defaultTz } from "../WDatePicker.tsx";
import { customCreateMutation, customUpdateMutation } from "./query.ts";

function dateToValue(date: CalendarDateTime | null) {
    return date != null ? date.toDate(defaultTz).toISOString() : undefined;
}

/** @returns Current date if the input is nullish */
function valueToDate(value: string | null | undefined) {
    const date = value ? apiDatetimeToDate(value) : new Date();
    return convertToCalendarDateTime(date);
}

function convertToCalendarDateTime(date: Date) {
    const time = fromAbsolute(date.valueOf(), "UTC");
    return toCalendarDateTime(toTimeZone(time, defaultTz));
}

export function AddProductButton({ button }: { button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditProductModalContent />
        </WModal>
    );
}

export function EditProductButton({ item, button }: { item: CustomBillableProduct; button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditProductModalContent item={item} />
        </WModal>
    );
}

interface QtyUnit {
    id: string;
    title: string;
}

const qtyUnitItems: QtyUnit[] = [
    { id: "pc", title: "pc" },
    { id: "h", title: "h" },
];

export function AddEditProductModalContent({ item }: { item?: CustomBillableProduct }) {
    const createMutation = useStandardMutation(customCreateMutation);
    const updateMutation = useStandardMutation(customUpdateMutation);
    const api = useApiClient();

    const placeholder = useMemo(() => convertToCalendarDateTime(new Date()), []);

    const defaultStartDate = valueToDate(item?.start_date);
    const defaultEndDate = item?.end_date ? valueToDate(item?.end_date) : null;

    const [description, setDescription] = useState<string>(item?.description ?? "");
    const [billing_account_id, setBillingAccountId] = useState<number | undefined>(item?.billing_account_id);
    const [price_per_unit, setPricePerUnit] = useState<number | undefined>(item?.price_per_unit);
    const [qty_unit, setQtyUnit] = useState<string>(item?.qty_unit ?? qtyUnitItems[0].id);
    const [startDate, setStartDate] = useState<CalendarDateTime | null>(defaultStartDate);
    const [endDate, setEndDate] = useState<CalendarDateTime | null>(defaultEndDate);
    const [error, setError] = useState<string>("");

    async function onSubmit() {
        const end_date = dateToValue(endDate);

        if (item) {
            await updateMutation.mutateAsync({
                body: {
                    end_date,
                },
                uuid: item.uuid,
            });
        } else {
            const start_date = notNull(dateToValue(startDate), "Start date");

            await createMutation.mutateAsync({
                body: {
                    description,
                    billing_account_id: notNull(billing_account_id),
                    price_per_unit: notNull(price_per_unit),
                    qty_unit,
                    start_date,
                    end_date,
                },
            });
        }
    }

    async function getBillingAccount(event: FocusEvent) {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const response = await api.GET("/payment/credit/admin_list", {
            params: { query: { billing_account_id: String(value) } },
        });

        setError(response.data ? "" : "Billing Account not found");
    }

    const isEditMode = !!item;

    return (
        <WModalContent
            isActionDisabled={!billing_account_id || !description || !price_per_unit || !qty_unit || !startDate}
            title={isEditMode ? "Edit Custom Billable Product" : "Add New Custom Billable Product"}
            label={isEditMode ? "Edit" : "Add"}
            modalAction={async () => await onSubmit()}
        >
            <WTextField
                autoFocus={!isEditMode}
                isRequired
                isReadOnly={isEditMode}
                wide
                label="Description"
                onChange={setDescription}
            >
                <AInput value={description} />
            </WTextField>

            <WNumberField
                isRequired
                isReadOnly={isEditMode}
                isInvalid={!!error}
                wide
                label="BA ID"
                errorMessage={error}
                value={billing_account_id}
                onBlur={getBillingAccount}
                onChange={(value) => setBillingAccountId(value)}
            >
                <AInput />
            </WNumberField>

            <WNumberField
                isRequired
                isReadOnly={isEditMode}
                wide
                value={price_per_unit}
                label="Price Per Unit"
                onChange={(value) => setPricePerUnit(value)}
            >
                <AInput />
            </WNumberField>

            <FormField wide label="Qty Unit">
                <WSelect
                    isDisabled={isEditMode}
                    getKey={(item) => item.id}
                    items={qtyUnitItems}
                    itemClassName={LR.item}
                    valueClassName={LR.value}
                    aria-label="Select qty unit"
                    getTextValue={(item) => item.title}
                    valueKey={qty_unit}
                    onChange={(item) => setQtyUnit(item.id)}
                >
                    {({ title }) => <LeftRight title={title} />}
                </WSelect>
            </FormField>

            <FormField isRequired wide label="Start Date">
                <WDateField
                    isReadOnly={isEditMode}
                    aria-label="Start date"
                    placeholderValue={placeholder}
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                />
            </FormField>

            <FormField
                // TODO: Allow clearing end date (for "infinite" products)
                wide
                label="End Date"
            >
                <WDateField
                    autoFocus={isEditMode}
                    aria-label="End Date"
                    // TODO: Validate that new end date is not in the past?
                    // NB: Placeholder *must* be set to a `CalendarDateTime` object, otherwise the time input will not be displayed when the field is empty
                    placeholderValue={placeholder}
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                />
            </FormField>
        </WModalContent>
    );
}
